import styled from "styled-components"
import { NavItem } from "../SubMenu/styled"
import { Link } from "gatsby"

const handleTextColor = ($isSelected: boolean, $anySelectedItems: boolean) => {
  if ($isSelected) return "#1C1E21"

  if ($anySelectedItems) return "#626874"

  return "#1C1E21"
}

export const ItemList = styled.div`
  flex-direction: column;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    margin: 0;
    gap: 32px;

    & div:first-child button {
      padding-left: 0px;
    }
  }
`

export const Item = styled(NavItem)<{
  $isSelected: boolean
  $anySelectedItems: boolean
}>`
  font-weight: 560;
  font-family: var(--font-mozaichum);
  align-content: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-decoration-color: #7e9dff;
    text-decoration-style: solid;
    text-underline-offset: 16px;
    text-decoration-thickness: 2px;
  }

  &:focus {
    border-radius: 8px;
  }

  button {
    cursor: pointer;
  }

  @media (max-width: 1023px) {
    button {
      font-size: 20px;
      font-weight: 420;
      line-height: 24px;
      font-family: var(--font-mozaichum);
    }
  }

  @media (min-width: 1024px) {
    img {
      display: none;
    }

    button {
      background-color: white;
      font-size: 16px;
      font-weight: 560;
      font-family: var(--font-mozaichum);
      line-height: 20px;
      color: ${({ $isSelected, $anySelectedItems }) =>
        handleTextColor($isSelected, $anySelectedItems)};
      transition: color 0.3s ease;
      text-decoration: ${({ $isSelected }) =>
        $isSelected ? "underline" : "none"};
      text-decoration-color: #7e9dff;
      text-decoration-style: solid;
      text-underline-offset: 16px;
      text-decoration-thickness: 2px;
    }

    padding: 0;
  }
`

export const SubMenuBackground = styled.div`
  position: fixed;
  top: 90px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 90px);
  background-color: rgb(0, 0, 0);
  opacity: 0.5;
  transition: opacity 0.3s ease;
  z-index: -2;
  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 0.5;
    transition: all 0.3s ease;
  }

  &.fade-exit {
    opacity: 0.5;
    transition: all 0.3s ease;
  }

  &.fade-exit-active {
    opacity: 0;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

export const Logo = styled(Link)`
  width: 40px;

  @media (min-width: 1024px) {
    width: 220px;
    max-width: 220px;
  }
`

export const Divider = styled.hr<{ $showOnDesktop?: boolean }>`
  border: none;
  background: #ededed;
  height: 1px;

  @media (min-width: 1024px) {
    margin: 0 40px;
    display: ${({ $showOnDesktop }) => ($showOnDesktop ? "block" : "none")};
  }
`

export const SubDivider = styled.hr<{ $showOnDesktop?: boolean }>`
  border: none;
  background: #c5c9ff;
  height: 1px;
  margin: 0 24px;

  @media (min-width: 1024px) {
    margin: 0 40px;
    display: ${({ $showOnDesktop }) => ($showOnDesktop ? "block" : "none")};
  }
`

export const DesktopMenuWrapper = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`
