import React, { useContext } from "react"
import { H3 } from "../../../ui"
import { Container, LinkWrapper, AssociatedLink } from "./styled"
import { LocationPageContext } from "../../LocationPageProvider"
import { createAssociatedLinksArray } from "./helpers"
import { SpecializationData } from "../../types"

export const CommonlySearchFor = (): JSX.Element => {
  const { associatedLocations, state, city, specialty, carePersonType } =
    useContext(LocationPageContext)

  const createAssociatedLinks = (): Array<SpecializationData> => {
    if (associatedLocations && (state || specialty)) {
      const arr = createAssociatedLinksArray(
        associatedLocations,
        city || state,
        specialty,
        carePersonType
      )
      return arr
    }
    return []
  }

  const associatedLinks = createAssociatedLinks()

  return (
    <Container>
      <H3>People commonly search for...</H3>
      <LinkWrapper>
        {associatedLinks.map(link => (
          <AssociatedLink href={link.url}>{link.linkTitle}</AssociatedLink>
        ))}
      </LinkWrapper>
    </Container>
  )
}
