import styled from "styled-components"

export const BannerContainer = styled.div`
  background-color: #eeefff;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 4px;
`

export const BannerText = styled.p`
  color: #393939;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  font-family: var(--font-poppins);

  a {
    color: inherit;
    font-weight: 500;
  }

  button {
    color: inherit;
    font-weight: 500;
  }
`

export const DataDeletionButton = styled.button`
  font-family: var(--font-poppins);
  border: none;
  background: none;
  text-decoration: underline;
  font-size: inherit;
  padding: 0;
  cursor: pointer;
  color: inherit;
`
