import ExternalLinkRebrand from "../../ui/ExternalLinkRebrand"
import type { ExternalLinkProps } from "../../ui/ExternalLinkRebrand/ExternalLinkRebrand"
import { useSignupUrl } from "../../../hooks/useSignupUrl"

interface IButton extends Omit<ExternalLinkProps, "to" | "href"> {
  href?: string
  skipPresignup?: boolean
}
export const GetStartedButtonRebrand = ({
  ...buttonProps
}: IButton): JSX.Element => {
  const signupUrl = useSignupUrl(buttonProps.skipPresignup)

  const buttonTitle = buttonProps.text || "Get started"
  return (
    <ExternalLinkRebrand
      {...buttonProps}
      href={buttonProps.href || signupUrl}
      text={buttonTitle}
      fontSize={buttonProps.fontSize || 14}
      paddingHorizontal={24}
      height={buttonProps.height || 56}
      fontFamily={buttonProps.fontFamily}
      fontWeight={buttonProps.fontWeight || 480}
    />
  )
}
