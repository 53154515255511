import React from "react"
import { H2, H3, BluredContainer, Picture } from "../../ui"
import { learnMoreContent } from "./constants"
import { ContentContainer, TextContainer, SectionDesc } from "./styled"

export const LearnMore = (): JSX.Element => {
  return (
    <BluredContainer layout="vertical" background="#F3F5FF">
      <H2>Want to learn more?</H2>
      <SectionDesc>
        If you're new to mental health care and not sure what you need, check
        out the resources below.
      </SectionDesc>
      <ContentContainer>
        {learnMoreContent.map(content => (
          <div key={content.title}>
            <Picture
              images={content.images}
              fallBack={content.fallback}
              alt={content.alt}
            />
            <TextContainer>
              <H3>{content.title}</H3>
              <p>{content.desc}</p>
              <a href={content.to}>Learn More</a>
            </TextContainer>
          </div>
        ))}
      </ContentContainer>
    </BluredContainer>
  )
}
