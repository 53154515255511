import React from "react"
import { Container, Text } from "./styled"

export type ExternalLinkProps = {
  id?: string
  text?: string
  leftIcon?: string
  rightIcon?: string
  href: string
  shadow?: boolean
  theme?: "primary" | "secondary" | "tertiary"
  paddingHorizontal?: number
  height?: number
  borderWidth?: number
  fontSize?: number
  children?: JSX.Element | string
  width?: number
  onClick?: () => void
  bgColor?: string
  fontFamily?: string
  fontWeight?: number
  border?: string
}

const ExternalLinkRebrand = ({
  id,
  text,
  shadow,
  href,
  theme = "primary",
  paddingHorizontal,
  height,
  borderWidth,
  fontSize,
  width,
  children,
  onClick,
  bgColor,
  fontFamily,
  fontWeight,
  border,
}: ExternalLinkProps): JSX.Element => {
  return (
    <Container
      id={id}
      href={href}
      $theme={theme}
      $paddinghorizontal={paddingHorizontal}
      $shadow={shadow}
      $height={height}
      $borderWidth={borderWidth}
      $width={width}
      onClick={onClick}
      $bgColor={bgColor}
      $border={border}
    >
      <Text
        $theme={theme}
        $fontSize={fontSize}
        $fontFamily={fontFamily}
        $fontWeight={fontWeight}
      >
        {text}
        {children}
      </Text>
    </Container>
  )
}

export default ExternalLinkRebrand
