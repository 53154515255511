import { Text } from "../../ui/ExternalLink/styled"
import { colors } from "../../themes/colors"
import styled from "styled-components"

export const MobileButtonContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  gap: 8px;
  padding: 0 16px;
  padding-bottom: 12px;
  margin-top: 72px;
  padding-top: 12px;
  border-top: 1px solid ${colors.c_grey[200]};
  background-color: ${colors.c_white[100]};

  a {
    width: 100%;
    height: 56px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`
