import { ModalState, useModalContext } from "../../../../contexts/ModalContext"
import {
  Button,
  H3,
  Span,
  SuccessActionContainer,
  SuccessContainer,
  SuccessTextContent,
} from "../styled"

const AvailableLocation = () => {
  const { setModalState } = useModalContext()

  return (
    <SuccessContainer>
      <SuccessTextContent>
        <H3>Yes, we have therapists available!</H3>
        <Span>
          You can choose who you’d like to work with and book a session right
          when you sign up
        </Span>
      </SuccessTextContent>
      <SuccessActionContainer>
        <div>
          <Button
            href="/get-started/couples-therapy"
            onClick={() => {
              setModalState(ModalState.CLOSED)
            }}
          >
            Get started
          </Button>
        </div>
      </SuccessActionContainer>
    </SuccessContainer>
  )
}

export default AvailableLocation
