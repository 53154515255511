import { IPostPillProps, NavigationItem } from "./types"
import headphonewomen from "./images/headphone-women.webp"

export const SERVICES = "Services"
export const INSURANCE = "Insurance"
export const ABOUT_CEREBRAL = "About Cerebral"
export const RESOURCES = "Resources"
export const BLOG = "Blog"
export const TESTS = "Free mental health tests"
export const TESTIMONIALS = "Testimonials"
export const SELF_TESTS = "Free mental health tests"

export const navBlogPost: IPostPillProps = {
  date: "2024-05-13T21:36:50.674Z",
  tag: ["Depression", "in the News"],
  title: "The Differences Between Bipolar Disorder and ADHD",
  image: {
    layout: "constrained",
    width: 64,
    height: 64,
    images: {
      fallback: {
        src: headphonewomen.src,
      },
    },
  },
  url: `/blog/bipolar-disorder-vs-adhd`,
}

export const navigationItems: NavigationItem[] = [
  {
    mainMenuTitle: SERVICES,
    subMenuOptions: [
      {
        subMenuTitle: "Medication + Psychiatry",
        link: "/plans/medication",
      },
      {
        subMenuTitle: "Individual Therapy",
        link: "/plans/therapy",
      },
      {
        subMenuTitle: "Couples Therapy",
        link: "/plans/couples-therapy",
      },
    ],
  },
  {
    mainMenuTitle: INSURANCE,
    subMenuOptions: [
      {
        subMenuTitle: "Check insurance coverage",
        link: "/insurance-partners",
      },
    ],
  },
  {
    mainMenuTitle: ABOUT_CEREBRAL,
    subMenuOptions: [
      {
        subMenuTitle: "Our Story",
        link: "/blog/introducing-cerebral-way",
      },
      {
        subMenuTitle: "Our clinicians",
        link: "/care-team",
      },
      {
        subMenuTitle: "Pricing",
        link: "/plans",
      },
      {
        subMenuTitle: "Prescription delivery",
        link: "/plans/prescription-delivery",
      },
    ],
  },
  {
    mainMenuTitle: RESOURCES,
    subMenuOptions: [
      {
        subMenuTitle: BLOG,
        link: "/blog",
        additionalComponent: {
          type: "Pill",
          pillProps: navBlogPost,
        },
      },
    ],
  },
]
