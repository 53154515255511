import React from "react"

import RightChevron from "../images/chevron-right.svg"
import { CommunityWrapper } from "../Community/styled"
import { SubDivider } from "../Menu/styled"
import { SubItemList, SubMenuItem } from "../SubMenu/styled"
import { SubNavOptions } from "../types"
import { Link } from "gatsby"

const items: SubNavOptions[] = [
  {
    subMenuTitle: "Find a psychiatrist",
    link: "/find-a-prescriber",
  },
  {
    subMenuTitle: "Find a therapist",
    link: "/find-a-therapist",
  },
]

const FindAClinician = () => {
  return (
    <CommunityWrapper>
      <h6>Find a clinician</h6>
      <SubDivider $showOnDesktop />
      <SubItemList>
        {items.map(item => (
          <Link to={item.link || "/"} key={item.subMenuTitle}>
            <SubMenuItem key={item.subMenuTitle}>
              <button>{item.subMenuTitle}</button>
              <img src={RightChevron} alt="right chevron arrow" />
            </SubMenuItem>
          </Link>
        ))}
      </SubItemList>
    </CommunityWrapper>
  )
}

export default FindAClinician
