import React from "react"

import RightChevron from "../images/chevron-right.svg"
import ClinicianTeam from "./images/clinician-team.png"
import { Wrapper, ArrowWrapper } from "./styled"

const BecomeAProvider = () => {
  return (
    <Wrapper href="https://get.cerebral.com/careers/">
      <div>
        <img src={ClinicianTeam} alt="gift icon" />
        <p>Become a provider</p>
      </div>
      <ArrowWrapper>
        <img src={RightChevron} alt="right chevron arrow" />
      </ArrowWrapper>
    </Wrapper>
  )
}
export default BecomeAProvider
