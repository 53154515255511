import { graphql } from "gatsby"
import styled from "styled-components"
import { getImage, GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { FAQSection } from "../shared/FAQSection"

const Container = styled.div`
  background: linear-gradient(135deg, #eceffe 0%, #fef9ec 100%);
  width: 100%;
`

const Wrapper = styled.div`
  display: grid;
  width: 992px;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 992px) {
    display: block;
    padding: 48px 24px;
    width: calc(100% - 48px);
  }
`

const LeftBox = styled.div``

const LeftBoxContainer = styled.div`
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 992px) {
    display: none;
  }
`

const RightBox = styled.div``

const RightBoxContainer = styled.div`
  width: 477px;
  margin: 64px 0;

  @media (max-width: 992px) {
    width: 100%;
    margin: 0;
  }
`

const MainImage = styled(GatsbyImage)`
  img {
    margin: 0 auto;
    object-fit: contain !important;
    max-height: 942px;
  }
`

const Title = styled.div`
  color: #250044;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 16px;
`

const PricingText = styled.div`
  color: #51459e;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
`

const Description = styled.div`
  color: #353a41;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;

  @media (max-width: 1024px) {
    font-size: 14px;
    margin-bottom: 0;
  }
`

const SelfTestText = styled.div`
  color: #51459e;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 20px;
  text-align: center;
  a {
    text-decoration: none;
    color: #51459e;
  }
`

export const Button = styled.a<{ disabled?: boolean }>`
  border-radius: 100px;
  background: ${props => (props.disabled ? "#F0F1F5" : "#51459e")};
  display: flex;
  width: 100%;
  height: 56px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${props => (props.disabled ? "#626874" : "#ffffff")};
  margin-bottom: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  cursor: ${props => (props.disabled ? "inherit" : "pointer")};

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 32px;
  }
`

interface MedicationIntroInterface {
  slice: {
    primary: {
      banner_image: {
        gatsbyImageData: IGatsbyImageData
      }
      med_text: {
        text: string
      }
      price_text: {
        text: string
      }
      description: {
        html: string
      }
      cta_text: {
        text: string
      }
      cta_link: {
        url: string
      }
      self_test_text: {
        text: string
      }
      self_test_link: {
        url: string
      }
      faq_label: string
    }
  }
}

const MedicationIntro = ({ slice }: MedicationIntroInterface): JSX.Element => {
  const {
    banner_image,
    med_text,
    price_text,
    description,
    cta_text,
    cta_link,
    self_test_text,
    self_test_link,
    faq_label,
  } = slice.primary

  return (
    <Container>
      <Wrapper>
        <LeftBox>
          <LeftBoxContainer>
            <MainImage
              image={getImage(banner_image) as IGatsbyImageData}
              alt=""
            />
          </LeftBoxContainer>
        </LeftBox>
        <RightBox>
          <RightBoxContainer>
            <Title>{med_text.text}</Title>
            <PricingText>{price_text.text}</PricingText>
            <Description
              dangerouslySetInnerHTML={{
                __html: description.html,
              }}
            ></Description>
            <Button href={cta_link.url}>{cta_text.text}</Button>
            <SelfTestText>
              <a href={self_test_link.url} target="">
                {self_test_text.text}
              </a>
            </SelfTestText>
            <FAQSection
              labels={[faq_label]}
              id="faq"
              noTitle={true}
              noPadding={true}
              margin="8px auto 0 auto"
            />
          </RightBoxContainer>
        </RightBox>
      </Wrapper>
    </Container>
  )
}

export const query = graphql`
  fragment PageMedicationIntroSlice on PrismicPageDataBodyMedicationIntro {
    id
    slice_type
    primary {
      banner_image {
        gatsbyImageData(placeholder: BLURRED, width: 350)
      }
      med_text {
        text
      }
      price_text {
        text
      }
      description {
        html
      }
      cta_text {
        text
      }
      cta_link {
        url
      }
      self_test_text {
        text
      }
      self_test_link {
        url
      }
      faq_label
    }
  }
`

export default MedicationIntro
