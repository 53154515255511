import styled from "styled-components"
import { colors, primaryButton, secondaryButton } from "../../themes/colors"

export const Container = styled.a<{
  theme: string
  paddinghorizontal?: number
  shadow?: boolean
  border?: number
  $borderWidth?: number // see https://styled-components.com/docs/api#transient-props for why we have to prepend this with a $
  borderColor?: string
  height?: number
  width?: number
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-style: normal;
  border-radius: 30px;
  height: ${p => p.height || 56}px;
  ${p => (p.width ? `width: ${p.width}px;` : "")}
  border: none;
  overflow: hidden;
  background: ${({ theme }) => {
    if (theme === "primary") return primaryButton.background
    if (theme === "secondary") return secondaryButton.background
    return "transparent"
  }};
  padding: 10px ${({ paddinghorizontal }) => paddinghorizontal || 16}px;

  ${({ theme = "primary", shadow }) =>
    theme === "primary" && shadow
      ? "box-shadow: 0 4px 13px rgba(34, 34, 52, 0.2);"
      : ""}
  ${({ theme }) =>
    theme === "secondary" ? `border: 1px solid ${colors.c_primary[600]};` : ""}
  border-width: ${p => p.$borderWidth || 1}px;
  white-space: nowrap;

  &:hover {
    ${({ theme }) =>
      theme === "primary" ? `background-color: ${colors.c_primary[700]}` : ""};
  }
`

export const Text = styled.span<{
  color?: string
  fontSize?: number
  theme: string
}>`
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  font-size: ${p => p.fontSize || 15}px;
  line-height: 20px;
  color: ${({ theme }) =>
    theme === "primary" ? primaryButton.text : secondaryButton.text};
`
