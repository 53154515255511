import styled from "styled-components"

export const BannerContainer = styled.div`
  background-color: #c5c9ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
export const BannerText = styled.p`
  color: #1c1e21;
  padding: 10px 30px;
  text-align: center;
  font-size: 14px;
  margin: 0 auto;
  line-height: 16px;
  font-family: Poppins;

  a {
    color: #1c1e21;
    font-weight: 500;
  }
`
