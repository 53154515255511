import {
  Container,
  Header,
  PlansContainer,
  PlanContainer,
  PlansHeader,
  PlansTitle,
  LearnMoreLink,
} from "./styled"
import CouplesIcon from "./assets/couples.svg"
import MedsIcon from "./assets/meds.svg"
import TherapyIcon from "./assets/therapy.svg"
import SunIcon from "./assets/sun.svg"
import ArrowRight from "./assets/arrow-right.svg"
import ArrowRightPurple from "./assets/arrow-right-purple.svg"
import { useWindowSize } from "../../../hooks"

const plansData = {
  meds: {
    icon: MedsIcon,
    title: "Medication Management",
    link: "/plans/medication",
  },
  therapy: {
    icon: TherapyIcon,
    title: "Individual Therapy",
    link: "/plans/therapy",
  },
  couples: {
    icon: CouplesIcon,
    title: "Couples Therapy",
    link: "/plans/couples-therapy",
  },
}

type PlanType = "meds" | "therapy" | "couples"

interface PlanCardProps {
  planType: PlanType
  index: number
}

export const PlanCard: React.FC<PlanCardProps> = ({ planType, index }) => {
  let border = false
  if (index === 1) {
    border = true
  }

  const { isMobile } = useWindowSize(1024)

  return (
    <PlanContainer href={plansData[planType].link} border={border}>
      <PlansHeader>
        <img
          height="64"
          width="64"
          src={plansData[planType].icon}
          alt={plansData[planType].title}
        />
        <PlansTitle>{plansData[planType].title}</PlansTitle>
      </PlansHeader>
      <LearnMoreLink href={plansData[planType].link}>
        Learn more <img src={ArrowRight} alt="arrow" />
      </LearnMoreLink>
      {isMobile && <img src={ArrowRightPurple} alt="arrow" />}
    </PlanContainer>
  )
}

export const HowCanWeHelp = () => {
  return (
    <Container>
      <img height="80" width="113" src={SunIcon} alt="Sun and birds" />
      <Header>How can we help</Header>
      <PlansContainer>
        {Object.keys(plansData).map((planType, i) => (
          <PlanCard key={planType} index={i} planType={planType as PlanType} />
        ))}
      </PlansContainer>
    </Container>
  )
}
