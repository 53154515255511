import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { Post } from "../../shared/Blog/types"
import { ConditionType } from "../../ConditionsLP/types"
import { H2 } from ".."
import { formatDate } from "../../shared/Blog/utils"
import {
  ConditionsContainer,
  ConditionContainer,
  Condition,
  TreatmentContainer,
  ParagraphsContainer,
  Links,
  BlogPill,
} from "./styled"

export const ConditionSection = ({
  condition,
  post,
}: {
  condition: ConditionType
  post?: Post
}): JSX.Element => {
  return (
    <ConditionsContainer id={condition.title.text}>
      <ConditionContainer key={condition.title.text}>
        <Condition>
          <H2>{condition.title.text}</H2>
          <ParagraphsContainer
            dangerouslySetInnerHTML={{
              __html: condition.desc.html,
            }}
          />
          {(condition.learn_more || condition.test_link) && (
            <Links>
              {condition.learn_more && (
                <a href={condition.learn_more.url}>
                  Learn more about {condition.learn_more_condition_title}
                </a>
              )}
              {condition.test_link && (
                <>
                  {" "}
                  |{" "}
                  <a href={condition.test_link.url}>Take a free online test</a>
                </>
              )}
            </Links>
          )}
          {post && (
            <BlogPill href={"https://cerebral.com" + post.url} target="_blank">
              <GatsbyImage
                image={post.image}
                alt=""
                style={{ flexShrink: 0, height: "64px", width: "64px" }}
              />
              <div>
                <p>{formatDate(post.date)}</p>
                <h3>{post.title}</h3>
              </div>
            </BlogPill>
          )}
        </Condition>
        <TreatmentContainer>
          <span>Treated with</span>
          {condition.therapy && <a href="/plans/therapy">Therapy</a>}
          {condition.medication && <a href="/plans/medication">Medication</a>}
        </TreatmentContainer>
      </ConditionContainer>
    </ConditionsContainer>
  )
}
