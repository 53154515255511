import styled from "styled-components"

export const NavBar = styled.div`
  background-color: #ffffff;
  padding: 0 40px;
  height: 90px;

  @media (max-width: 1023px) {
    padding: 0 24px;
    height: 72px;
  }
`

export const InnerWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  height: 100%;
`

export const MobileMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  max-width: 462px;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: 1024px) {
    width: 100%;
  }
`

export const LoginButton = styled.a`
  display: none;

  @media (min-width: 1024px) {
    color: #393939;
    font-weight: 400;
    line-height: 20px;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    width: 100px;
    height: 56px;
    text-underline-offset: 5px;
    text-decoration: underline 3px #c5c9ff;
    display: grid;
    place-items: center;
    font-family: var(--font-mozaichum);
  }
`

export const SubMenu = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 88px;
  left: 0;
  background-color: #ffffff;
  width: 33%;
  height: calc(100vh - 88px);
  left: ${({ $isOpen }) => ($isOpen ? "0" : "-33%")};
  transition: left 0.3s ease-in-out;
  z-index: 999;
  border: 1px solid black;
`

export const LogoWrapper = styled.a`
  text-align: left;
`

export const Logo = styled.img`
  width: 40px;

  @media (min-width: 1024px) {
    width: 180px;
    max-width: 180px;
  }

  @media (min-width: 1366px) {
    width: 220px;
    max-width: 220px;
  }
`

export const HamburgerIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:active {
    background: #e5ebff;
    border-radius: 50%;
  }

  img {
    width: 24px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

export const NavWrapper = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #ffffff;
`
