import styled from "styled-components"
import { colors } from "../../themes/colors"

export const ConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 20px;
  gap: 40px;
  align-self: center;
  background: ${colors.c_white[500]};
  @media (max-width: 600px) {
    padding: 40px 24px;
    width: 100%;
  }
  margin: 24px 0;
  * {
    font-family: Poppins;
  }
`
export const ConditionContainer = styled.div`
  display: flex;
  gap: 48px;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 32px;
  }
`

export const Condition = styled.div`
  display: flex;
  flex-direction: column;
  & > h2 {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 8px;
    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
`
export const ParagraphsContainer = styled.div`
  & > p {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  & > p:last-child {
    margin-bottom: 0px;
  }
`
export const TreatmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  flex-shrink: 0;
  & > span {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  }
  & > a {
    margin-top: 8px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.c_secondary[600]};
  }
  @media (max-width: 600px) {
    margin-left: 0;
    margin-top: 16px;
  }
`

export const Links = styled.div`
  margin: 16px 0;
  color: ${colors.c_grey[300]};
  & > a {
    color: ${colors.c_secondary[600]};
    line-height: 24px;
  }
`

export const BlogPill = styled.a`
  margin-top: 16px;
  background-color: ${colors.c_secondary[50]};
  padding: 16px;
  border-radius: 20px;
  min-height: 96px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  text-align: left;
  text-decoration: none;

  p {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    color: ${colors.c_grey[900]};
    line-height: 16px;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: ${colors.c_black[900]};
  }
`
