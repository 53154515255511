import styled from "styled-components"
import { colors } from "../../../../themes/colors"

export const Back = styled.a`
  display: flex;
  gap: 8px;

  text-decoration: none;

  color: ${colors.c_secondary[600]};
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
`
export const Nav = styled.nav<{ scrolled: boolean }>`
  position: sticky;
  z-index: 1;
  background: ${props =>
    props.scrolled ? colors.c_white[500] : "rgb(242, 245, 255)"};
  box-shadow: ${props =>
    props.scrolled ? "0px 4px 4px rgba(0, 0, 0, 0.1)" : "none"};

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  min-height: 60px;
  top: 141px;

  @media (min-width: 834px) {
    top: 141px;
  }
  @media (min-width: 443px) {
    top: 126px;
  }
`
