import { useSignupUrl } from "../../../hooks/useSignupUrl"
import ExternalLinkRebrand from "../../ui/ExternalLinkRebrand"
import { GetStartedButtonRebrand } from "../GetStartedButtonRebrand"
import { MobileButtonContainerWrapper } from "./styled"

export const MobileButtonContainer = () => {
  const signupUrl = useSignupUrl()

  return (
    <MobileButtonContainerWrapper>
      <ExternalLinkRebrand
        fontFamily="var(--font-mozaichum)"
        fontSize={18}
        bgColor="#DBDEFF"
        theme="secondary"
        href={signupUrl}
        text="Log In"
        border="none"
      />
      <GetStartedButtonRebrand
        fontWeight={400}
        fontSize={18}
        fontFamily="var(--font-mozaichum)"
      />
    </MobileButtonContainerWrapper>
  )
}
