import React from "react"
import FiveStar from "../Navigation/images/five-stars.svg"
import {
  NavDrawerWrapper,
  TestimonialCard,
  TestimonialName,
  TestimonialText,
  TestimonialWrapper,
} from "./styled"
import { singleTestimonial } from "./constants"

export interface ServiceProps {
  servicePlan: "therapy" | "medication"
}

export const NavDrawerTestimonial = ({
  servicePlan,
}: ServiceProps): JSX.Element => {
  const testimonials = singleTestimonial[servicePlan]

  return (
    <NavDrawerWrapper>
      <TestimonialWrapper>
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index} to={"/testimonials"}>
            <TestimonialText>{testimonial.quote}</TestimonialText>
            <TestimonialName>{testimonial.name}</TestimonialName>
            <img src={FiveStar} alt="5 stars" width={97} height={17} />
          </TestimonialCard>
        ))}
      </TestimonialWrapper>
    </NavDrawerWrapper>
  )
}
