import styled from "styled-components"

export const Wrapper = styled.a`
  background: #f9f7f2;
  border: 1px solid #7866a8;
  border-radius: 16px;
  margin: 0px 24px 40px;
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  text-decoration: none;
  color: #393939;
  justify-content: space-between;
  font-family: var(--font-mozaichum);

  img {
    width: 40px;
    height: 40px;
  }

  @media (min-width: 1024px) {
    margin: 8px 40px 40px;
  }

  &:hover {
    background: #e5ebff;
  }

  &:focus {
    border-color: #385ade;
    background: #e5ebff;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
`

export const ArrowWrapper = styled.p`
  img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
`
