import axios from "axios"
import {
  GetFaqsResponse,
  GetSectionsForCategoryResponse,
  SectionResponse,
} from "../types/ZendeskApi"

//in new web repo, use useQuery instead
export async function fetchFaqs({
  labels = [],
  sectionId,
}: {
  labels?: string[]
  sectionId?: number
}): Promise<GetFaqsResponse> {
  const url = `${
    sectionId && !labels.length ? `sections/${sectionId}/articles` : "articles"
  }`

  try {
    const { data } = await axios.get<GetFaqsResponse>(url, {
      baseURL: `${process.env.GATSBY_ZENDESK_API_URL}/en-us`,
      params: {
        ...(labels.length && { label_names: labels?.join(",") }),
      },
    })

    return data
  } catch (error) {
    console.warn(error)

    throw new Error("Error fetching FAQs")
  }
}

async function getSectionCountById(sectionId: number) {
  try {
    const { data } = await axios.get(
      `${process.env.GATSBY_ZENDESK_API_URL}/articles/search`,
      {
        params: { section: sectionId },
      }
    )

    return data.count || 0
  } catch (error) {
    console.error("Fetching FAQ count failed for section:", sectionId, error)

    return 0
  }
}

export async function fetchSectionCount({
  sectionIds = [],
}: {
  sectionIds: number[]
}): Promise<Record<string, string>> {
  const newCounts: Record<string, string> = {}

  const response = await Promise.all(sectionIds.map(getSectionCountById))

  sectionIds.forEach((id, index) => {
    newCounts[id] = response[index]
  })

  return newCounts
}

async function getSectionName(sectionId: number) {
  try {
    const response = await axios.get<SectionResponse>(
      `${process.env.GATSBY_ZENDESK_API_URL}/en-us/sections/${sectionId}/`
    )
    return response.data.section.name
  } catch (error) {
    console.warn("Fetching FAQs name failed for section:", sectionId, error)
    return ""
  }
}

export async function fetchSectionNames({
  sectionIds = [],
}: {
  sectionIds: number[]
}): Promise<Record<string, string>> {
  const newNames: Record<string, string> = {}

  const uniqueSectionIds = Array.from(new Set(sectionIds))

  const response = await Promise.all(uniqueSectionIds.map(getSectionName))

  sectionIds.forEach((id, index) => {
    newNames[id] = response[index]
  })

  return newNames
}

export async function getSectionsForCategory() {
  try {
    const { data } = await axios.get<GetSectionsForCategoryResponse>(
      `${process.env.GATSBY_ZENDESK_API_URL}/en-us/categories/${process.env.GATSBY_ZENDESK_API_FAQ_CATEGORY_ID}/sections`
    )
    return data.sections
  } catch (error) {
    console.warn("Error fetching sections:", error)
  }
}
