import styled from "styled-components"
import { colors } from "../../themes/colors"

interface PlansWrapper {
  padding?: string
  background?: string
}

export const PlansWrapper = styled.section<PlansWrapper>`
  padding: ${({ padding }) => padding || "72px 0 48px"};
  display: flex;
  flex-direction: column;
  & > h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  background: ${({ background }) =>
    background ||
    `linear-gradient(
    180deg,
    ${colors.c_white[500]} 0%,
    #eceffe 50%,
    #eceffe,
    ${colors.c_white[500]} 100%
  )`};

  @media (max-width: 600px) {
    background: ${colors.c_white[500]};
  }
`

export const PlansDesktopWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 48px;
  @media (max-width: 1022px) {
    display: none;
  }
`

export const PlansMobileWrapper = styled.div`
  display: none;
  @media (max-width: 1022px) {
    display: block;
  }
`

export const Description = styled.p`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: ${colors.c_black[500]};
  white-space: pre-line;
  max-width: 760px;
  align-self: center;
  padding: 0 8px;
`
export const LearnMore = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: ${colors.c_secondary[600]};
`
export const AsteriskText = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: ${colors.c_black[500]};
  margin: 16px auto 0;
  padding: 0 8px;
`

export const MoreLikelyText = styled.span`
  background: ${colors.c_secondary[200]};
  mix-blend-mode: multiply;
  border-radius: 12px;
  padding: 1px 5px;
`
export const StrongStartText = styled.span`
  background: ${colors.c_yellow[400]};
  mix-blend-mode: multiply;
  border-radius: 12px;
  padding: 1px 5px;
`
