import useSWR from "swr"
import { fetchFaqs } from "../../../hooks/useZendeskApi"
import { FaqResult, Section } from "../../../types/ZendeskApi"
import { useWindowSize } from "../../hooks"
import SEO from "../../SEO"
import { Navigation } from "../../ui/Navigation"
import { FaqCategoryLinks, FaqTopics } from "../shared"
import { BackButton, BackToTop, Faqs, Heading } from "./sections"
import { schemaMarkup } from "./seoSchema"
import { CategoryLinksContainer, FaqCategoryPageBody } from "./styled"

export const FaqCategoryBody = ({
  section,
  faqs,
}: {
  section: Section
  faqs: FaqResult[] | undefined
}): JSX.Element => {
  const { isMobile } = useWindowSize()

  return (
    <FaqCategoryPageBody>
      {isMobile && <BackButton />}
      <Heading section={section} />
      <>
        <FaqTopics titleType="number" sectionId={section.id} />
        <Faqs faqs={faqs} />
      </>
      <CategoryLinksContainer>
        <FaqCategoryLinks />
      </CategoryLinksContainer>
      <FaqTopics title={"Popular Topics"} labels={["popular_topics_faq"]} />
      <BackToTop />
    </FaqCategoryPageBody>
  )
}

export const FaqCategoryPage = ({
  section,
}: {
  section: Section
}): JSX.Element => {
  const { data: faqs } = useSWR({ sectionId: section.id }, fetchFaqs)

  return (
    <>
      <SEO
        title={`${section.name} | FAQs | Cerebral`}
        description="All the info on Cerebral, all in one place."
        schemaMarkup={faqs && schemaMarkup(faqs.articles)}
      />
      <Navigation />
      <FaqCategoryBody section={section} faqs={faqs?.articles} />
    </>
  )
}
