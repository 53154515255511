import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const Container = styled.section`
  display: flex;
  justify-content: center;
  margin: 135px auto;
  @media (max-width: 834px) {
    display: flex;
    justify-content: center;
    align-items: left;
    gap: 0;
    margin: 64px auto;
  }
  @media (min-width: 600px) {
    display: flex;
    align-items: center;
    margin: 16px;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  gap: 72px;
  @media (max-width: 834px) {
    gap: 0px;
    display: flex;
    flex-direction: column;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 543px;
  margin-top: 42px;
  & h3 {
    margin-bottom: 24px;
  }
  & p {
    font-weight: 300;
  }
  @media (max-width: 834px) {
    margin-top: auto;
    & h3 {
      display: none;
      margin: 16px;
    }
  }
`

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  & h3 {
    margin-bottom: auto;
    margin-top: auto;
  }
  & img {
    max-width: 100%;
    height: auto;
  }
  margin-bottom: 24px;
  @media (min-width: 834px) {
    & h3 {
      display: none;
    }
    & img {
      max-width: none;
      height: none;
    }
  }
`

export const LearnMore = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: ${colors.c_secondary[600]};
  margin: 12px 0;
`
