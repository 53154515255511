import axios from "axios"
import { ICarePerson } from "../CarePersonPage/types"
import segment, { segmentEvents } from "../../lib/segment"
import { navigate } from "@reach/router"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"

export const handleBook = async (
  therapistsData: ICarePerson,
  timeSelected: string
) => {
  const state = therapistsData.available_states[0]

  try {
    const data = await axios.post(
      `${process.env.GATSBY_API_SERVER_URL}/api/anonymous_appointment`,
      {
        starts_at: timeSelected,
        clinician_email: therapistsData.email,
        state,
      }
    )
    const segmentData = {
      anonymous_id: data.data.anonymous_appointment_id,
      initital_state: state,
      chosen_state: state,
      clinician_email: therapistsData.email,
      appointment_date: timeSelected.split("T")[0],
      appointment_time: timeSelected.split("T")[1],
      succeed: true,
      created_at: new Date().toISOString(),
    }
    segment.track(segmentEvents.BOOK_SESSION_BIO_PAGE, segmentData, {
      integrations: {
        All: false,
        Heap: true,
      },
    })

    window.location.href = `${process.env.GATSBY_START_URL}?anonymous_id=${data.data.anonymous_appointment_id}`
  } catch (e) {
    const segmentData = {
      state: state,
      clinician_email: therapistsData.email,
      appointment_date: timeSelected.split("T")[0],
      appointment_time: timeSelected.split("T")[1],
      succeed: false,
      error_message: e,
      created_at: new Date().toISOString(),
    }
    segment.track(segmentEvents.BOOK_SESSION_BIO_PAGE, segmentData, {
      integrations: {
        All: false,
        Heap: true,
      },
    })
  }
}

export const GeneratedGatsbyImage = ({
  imageData,
  alt,
}: {
  imageData: ImageDataLike
  alt: string
}): JSX.Element => {
  const image = getImage(imageData)
  if (image) {
    return <GatsbyImage objectFit="contain" image={image} alt={alt} />
  } else {
    return <></>
  }
}
