import styled from "styled-components"

export const NavItem = styled.div`
  list-style: none;
  height: 72px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;

  img {
    width: 8px;
  }

  &:hover {
    background: #e5ebff;
  }

  &:focus {
    border: 2px solid #385ade;
  }

  &:active {
    background: #cbd8ff;
  }

  button {
    background: none;
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: #1c1e21;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    padding: 0;
  }

  @media (min-width: 1024px) {
    margin: 0px;
    padding: 0 40px;
    height: 72px;
    white-space: nowrap;

    button {
      padding: 0;
    }
  }
`

export const SubMenuItem = styled(NavItem)<{
  $noLink?: boolean
}>`
  &:hover {
    ${({ $noLink }) => $noLink && `background: none;`}
  }

  button {
    ${({ $noLink }) => $noLink && `cursor: default;`}
  }

  button {
    font-size: 20px;
    font-weight: 420;
    line-height: 24px;
    font-family: var(--font-mozaichum);
  }

  img {
    margin: auto 0;
    height: fit-content;
    width: 24px;
    padding: 8px;
  }
`

export const ItemListWrapper = styled.div<{
  $isOpen: boolean
  $disableTransition: boolean
}>`
  position: fixed;
  top: 0;
  left: ${({ $isOpen }) => ($isOpen ? 0 : "-100%")};
  transition: ${({ $disableTransition }) =>
    $disableTransition ? "none" : "left 0.3s ease-in-out"};
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  overflow-y: overlay;
  background-color: #ffffff;
  padding-bottom: 100px;

  @media (min-width: 1024px) {
    z-index: -1;
    display: none;
  }
`

export const SubMenuWrapper = styled(ItemListWrapper)<{
  $isOpen?: boolean
  $shouldPadTop: boolean
}>`
  @media (min-width: 1024px) {
    display: block;
    top: ${({ $shouldPadTop }) => ($shouldPadTop ? "158px" : "90px")};
    width: 540px;
    height: calc(100vh - 90px);
    ${({ $isOpen }) => `left: ${$isOpen ? "0" : "-540px"};`}
    transition: ${({ $disableTransition }) =>
      $disableTransition ? "none" : "left 0.3s ease-in-out"};
  }
`

export const SubItemList = styled.div`
  flex-direction: column;

  a {
    text-decoration: none;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
`

export const BackIcon = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  height: 40px;
  align-items: center;
  justify-content: center;
  color: #1c1e21;

  img {
    width: 14px;
    height: 14px;
    margin-right: 16px;
  }

  p {
    padding-right: 8px;
  }
  &:active {
    background: #e5ebff;
    border-radius: 6px;
  }
`

export const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 24px;
  height: 72px;
  align-items: center;
`

export const SubMenuHeader = styled(MobileHeader)`
  border-bottom: 1px solid #f9f7f2;

  p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 420;
    font-family: var(--font-mozaichum);
  }

  @media (min-width: 1024px) {
    display: none;
  }
`
