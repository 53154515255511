import React from "react"

import LogoImage from "./images/new-logo.svg"
import LogoOnlyImage from "./images/logo-only.svg"
import HamburgerMenu from "./images/gray-hamburger.svg"
import { useState } from "react"
import FTCBanner from "./FTCBanner"
import Menu from "./Menu"
import { SiteBanner } from "./SiteBanner"
import {
  ButtonContainer,
  HamburgerIconWrapper,
  InnerWrapper,
  LoginButton,
  Logo,
  LogoWrapper,
  MobileMenuWrapper,
  NavBar,
  NavWrapper,
} from "./styled"
import { useWindowSize } from "../../hooks"
import { GetStartedButtonRebrand } from "../../shared/GetStartedButtonRebrand"

export const Navigation = () => {
  const { isMobile } = useWindowSize(768)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isSiteBannerLoading, setIsSiteBannerLoading] = useState(true)

  return (
    <>
      {!isSiteBannerLoading && <FTCBanner />}

      <SiteBanner setIsBannerLoading={setIsSiteBannerLoading} />

      <NavWrapper>
        <NavBar>
          <InnerWrapper>
            <Menu
              isMobileMenuOpen={isMobileMenuOpen}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
            />
            <MobileMenuWrapper>
              <LogoWrapper href="/">
                <Logo
                  src={isMobile ? LogoOnlyImage : LogoImage}
                  alt="Cerebral logo"
                />
              </LogoWrapper>
            </MobileMenuWrapper>
            <ButtonContainer>
              <LoginButton href={process.env.GATSBY_LOGIN_URL || "/"}>
                Log in
              </LoginButton>
              <GetStartedButtonRebrand
                height={isMobile ? 40 : 56}
                fontSize={isMobile ? 16 : 18}
                fontFamily="var(--font-mozaichum)"
                paddingHorizontal={14}
                fontWeight={480}
              />
              <HamburgerIconWrapper onClick={() => setIsMobileMenuOpen(true)}>
                <img
                  src={HamburgerMenu}
                  alt="hamburger menu image"
                  width={20}
                />
              </HamburgerIconWrapper>
            </ButtonContainer>
          </InnerWrapper>
        </NavBar>
      </NavWrapper>
    </>
  )
}
