import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const PopularLocationsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  h3 {
    margin: 40px 0px 32px;
    font-size: 22px;
    @media (max-width: 834px) {
      max-width: 327px;
    }
  }
  @media (max-width: 1024px) {
    margin-left: 24px;
  }
`

export const PopularLocationsList = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 834px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const DesktopColumn = styled.div`
  display: block;
  @media (max-width: 834px) {
    display: none;
  }
`

export const MobileColumn = styled.div`
  display: none;
  @media (max-width: 834px) {
    display: block;
  }
`

export const LocationLinkContainer = styled.div`
  margin: 16px 0;
`

export const LocationLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.c_secondary[600]};
`
