import styled from "styled-components"
import { colors } from "../../../../themes/colors"

export const Container = styled.section`
  background: #eff2ff;
  background: linear-gradient(
    180deg,
    rgba(242, 245, 255, 1) 91%,
    rgba(255, 255, 255, 1) 101%
  );
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 96px 176px;
  padding: 8% 12%;
  @media (max-width: 499px) {
    padding: 88px 16px;
  }
`

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  margin-top: 80px;
`

export const Category = styled.div`
  display: flex;
  background: ${colors.c_white[500]};
  border-radius: 18px;
  border: 1px solid #a8baff;
  width: 280px;
  height: 80px;
  justify-content: space-between;
  padding: 16px 24px;
`
export const CategoryTitle = styled.h2`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #222128;
`

export const CategoryText = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TopicsLink = styled.a`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: ${colors.c_secondary[600]};
`
