import React from "react"

import RightChevron from "../images/chevron-right.svg"
import { CommunityWrapper } from "../Community/styled"
import { SubDivider } from "../Menu/styled"
import { SubItemList, SubMenuItem } from "../SubMenu/styled"
import { SubNavOptions } from "../types"
import { Link } from "gatsby"

const ourProvidersItems: SubNavOptions[] = [
  {
    subMenuTitle: "Blue Cross Blue Shield",
    link: "https://cerebral.com/insurance/blue-cross-blue-shield",
  },
  {
    subMenuTitle: "Cigna",
    link: "https://cerebral.com/insurance/cigna",
  },
  {
    subMenuTitle: "United Healthcare",
    link: "https://cerebral.com/insurance/united-health-care",
  },
  {
    subMenuTitle: "Optum",
    link: "https://cerebral.com/insurance/optum",
  },
]

const OurProviders = () => {
  return (
    <CommunityWrapper>
      <h6>Our insurance partners</h6>
      <SubDivider $showOnDesktop />
      <SubItemList>
        {ourProvidersItems.map(item => (
          <Link to={item.link || "/"} key={item.subMenuTitle}>
            <SubMenuItem key={item.subMenuTitle}>
              <button>{item.subMenuTitle}</button>
              <img src={RightChevron} alt="right chevron arrow" />
            </SubMenuItem>
          </Link>
        ))}
      </SubItemList>
    </CommunityWrapper>
  )
}

export default OurProviders
