import { ModalState, useModalContext } from "../../../../contexts/ModalContext"
import {
  Bold,
  Button,
  H3,
  Span,
  SuccessActionContainer,
  SuccessContainer,
  SuccessTextContent,
} from "../styled"

const SuccessfulJoin = () => {
  const { setModalState } = useModalContext()

  return (
    <SuccessContainer>
      <SuccessTextContent>
        <H3>Great, you're on the waitlist!</H3>
        <Span>
          Thanks for your interest in Couples Therapy at Cerebral.
          <br />
          <br />
          We’ll text or email you as soon as we have therapists available in
          your state.
          <br />
          <br />
          <Bold>In the meantime</Bold> — we have some great resources to help
          you and your partner learn more about building healthy relationships.
        </Span>
      </SuccessTextContent>
      <SuccessActionContainer>
        <div>
          <Button
            href="/blog/couples-therapy-vs-marriage-counseling"
            onClick={() => {
              setModalState(ModalState.CLOSED)
            }}
          >
            Visit our blog
          </Button>
        </div>
      </SuccessActionContainer>
    </SuccessContainer>
  )
}
export default SuccessfulJoin
