import React from "react"

import { Wrapper, TestimonialWrapper, TextWrapper } from "./styled"
import { testimonials } from "./constants"
import quotationImagePrimary from "../assets/quotes-primary.svg"
import quotationImageSecondary from "../assets/quotes-secondary.svg"
import { H1 } from "../../ui"
import { TestimonialsContainer, TextLink } from "./styled"
import { QuotesCarousel } from "./QuotesCarousel"

interface QuotationsProps {
  theme?: string
}

interface ITestimonialsProps {
  quotes: {
    quote: string
    name: string
  }[]
  btnTextDecoration?: string
}
export const Testimonials = ({
  theme = "primary",
}: QuotationsProps): JSX.Element => {
  return (
    <Wrapper>
      <img
        src={
          theme === "secondary"
            ? quotationImageSecondary
            : quotationImagePrimary
        }
        alt=""
        loading="lazy"
        width={63}
        height={45}
      />
      <TestimonialWrapper>
        {testimonials.map(({ id, content, center }) => (
          <TextWrapper key={id} center={center} theme={theme}>
            {content}
          </TextWrapper>
        ))}
      </TestimonialWrapper>
    </Wrapper>
  )
}

// renamed below because Testimonials exported on line 22
export const SuccessTestimonials = ({
  quotes,
  btnTextDecoration,
}: ITestimonialsProps): JSX.Element => {
  return (
    <TestimonialsContainer>
      <H1>Success stories</H1>
      <QuotesCarousel quotes={quotes} />
      <TextLink href="/testimonials" textDecoration={btnTextDecoration}>
        See what else people are saying
      </TextLink>
    </TestimonialsContainer>
  )
}
