import styled, { css } from "styled-components"

export const ModalContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;

  @media (max-width: 834px) {
    flex-direction: column;
    overflow: auto;
  }
`

export const IconContainer = styled.div`
  position: absolute;
  padding: 16px;
  top: 16px;
  right: 8px;
  z-index: 103;
  cursor: pointer;

  > * {
    color: #250044;
  }

  @media (max-width: 834px) {
    top: 0;
    right: 0;

    > * {
      color: white;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 38px 56px;
  overflow: hidden;

  @media (max-width: 834px) {
    padding: 0;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  overflow: visible;

  @media (max-width: 834px) {
    padding: 24px;
    overflow: auto;
  }
`

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  @media (max-width: 834px) {
    justify-content: flex-start;
  }
`

export const SuccessTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 38px 56px;
  gap: 24px;

  @media (max-width: 834px) {
    flex: 1;
    padding: 24px;
    justify-content: flex-start;
  }
`

export const SuccessActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 56px;
    padding-top: 16px;

    @media (max-width: 834px) {
      padding: 16px 24px;
      box-shadow: 0px -2px 8px -4px rgba(28, 30, 33, 0.16);
    }
  }
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46%;
  position: relative;

  @media (max-width: 834px) {
    width: 100%;
    height: 211px;
  }
`

export const ImagePlaceholder = styled.div<{ loaded: boolean }>`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.5s;
  z-index: -1;

  ${({ loaded }) =>
    loaded &&
    css`
      opacity: 0;
    `};
`

export const Image = styled.img<{ loaded: boolean }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s;

  ${({ loaded }) =>
    loaded &&
    css`
      opacity: 1;
    `}
`

export const H3 = styled.h3`
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  color: #1c1e21;
`

export const Span = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #353a41;
`

export const Bold = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: #353a41;
  font-weight: 600;
`

export const StyledLink = styled.a`
  color: #51459e;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #353a41;
`

export const Input = styled.input`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid #e1e2e8;
  padding: 12px;

  &:active,
  &:focus {
    outline: 2px solid #51459e;
    border: 1px solid transparent;
  }
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 16px;

    @media (max-width: 834px) {
      padding: 16px 24px;
      box-shadow: 0px -2px 8px -4px rgba(28, 30, 33, 0.16);
    }
  }
`

export const Button = styled.a<{ disabled?: boolean }>`
  padding: 20px 24px;
  border-radius: 100vh;
  background-color: #51459e;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  border: none;
  cursor: pointer;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  appearance: none;

  ${({ disabled }) =>
    !!disabled &&
    css`
      background-color: #f0f1f5;
      color: #626874;
      cursor: inherit;
    `}
`

export const ErrorMessage = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #ff0000;
`
