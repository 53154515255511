"use client"

import styled from "styled-components"

export const Container = styled.a<{
  $theme: string
  $paddinghorizontal?: number
  $shadow?: boolean
  $border?: string
  $borderWidth?: number
  $borderColor?: string
  $height?: number
  $width?: number
  $bgColor?: string
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-style: normal;
  border-radius: 30px;
  height: ${p => p.$height || 56}px;
  ${p => (p.$width ? `width: ${p.$width}px;` : "")}
  border: none;
  overflow: hidden;
  background: ${({ $theme, $bgColor }) => {
    if ($bgColor) return $bgColor
    if ($theme === "primary") return "#443962"
    if ($theme === "secondary") return "#FFFFFF"
    return "transparent"
  }};
  padding: 10px ${({ $paddinghorizontal }) => $paddinghorizontal || 16}px;

  ${({ $theme = "primary", $shadow }) =>
    $theme === "primary" && $shadow
      ? "box-shadow: 0 4px 13px rgba(34, 34, 52, 0.2);"
      : ""}
  ${({ $theme, $border }) =>
    $border
      ? $border
      : $theme === "secondary"
      ? `border: 1px solid #443962;`
      : ""}
  border-width: ${p => p.$borderWidth || 1}px;
  white-space: nowrap;

  &:hover {
    ${({ $theme }) =>
      $theme === "primary" ? `background-color: #5A4B84` : ""};
  }

  &:focus {
    outline: 2px solid #c5c9ff;
    outline-offset: 2px;
  }
`

export const Text = styled.span<{
  $color?: string
  $fontSize?: number
  $theme: string
  $fontFamily?: string
  $fontWeight?: number
}>`
  font-style: normal;
  font-weight: ${({ $fontWeight }) => $fontWeight || 400};
  font-size: ${({ $fontSize }) => $fontSize || 15}px;
  line-height: 20px;
  color: ${({ $theme }) => ($theme === "primary" ? "#FFFFFF" : "#443962")};
  font-family: ${({ $fontFamily }) => $fontFamily || "var(--font-poppins)"};
`
