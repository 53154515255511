import React from "react"
import { Container, Text } from "./styled"

export type ButtonProps = {
  id?: string
  text?: string
  leftIcon?: Object
  rightIcon?: Object
  to: string
  onClick?: () => void
  shadow?: boolean
  theme?: "primary" | "secondary" | "tertiary"
  paddingHorizontal?: number
  height?: number
  borderWidth?: number
  fontSize?: number
  children?: JSX.Element
  width?: number
}

export const Button = ({
  id,
  text,
  shadow,
  to,
  onClick,
  theme = "primary",
  paddingHorizontal,
  height,
  borderWidth,
  fontSize,
  width,
  children,
}: ButtonProps): JSX.Element => {
  return (
    <Container
      id={id}
      href={to}
      onClick={e => {
        if (onClick) {
          e.preventDefault()
          onClick()
        }
      }}
      theme={theme}
      paddinghorizontal={paddingHorizontal}
      shadow={shadow}
      height={height}
      $borderWidth={borderWidth}
      width={width}
    >
      <Text theme={theme} fontSize={fontSize}>
        {text}
        {children}
      </Text>
    </Container>
  )
}
