import { navigate } from "gatsby"
import useSWR from "swr"
import { fetchFaqs, fetchSectionNames } from "../../../hooks/useZendeskApi"
import segment, { segmentEvents } from "../../../lib/segment"
import { FaqResult } from "../../../types/ZendeskApi"
import { convertToKebabCase } from "../../FaqPages/shared/utils"
import { H2, H3 } from "../../ui"
import { Column, Container, TopicLink, TopicsContainer } from "./styled"

// These are for 'About Cerebral', 'Therapy', 'Medication', and 'Plans, Costs & Insurance', respectively
const SECTION_IDS = [
  27093619229719, 27093609815063, 27093655356055, 27093645725847,
]

export const FaqTopics = ({
  title,
  titleType = "string",
  labels,
  sectionId,
  modifiedHeader,
}: {
  title?: string
  titleType?: "string" | "number"
  labels?: string[]
  sectionId?: number
  modifiedHeader?: boolean
}) => {
  const { data: faqs } = useSWR({ labels, sectionId }, fetchFaqs)
  const sectionIds = SECTION_IDS
  const { data: sectionNames } = useSWR(
    { search: "names", sectionIds },
    fetchSectionNames
  )
  if (!faqs || faqs.count === 0) return null

  const middle = Math.ceil(faqs.articles.length / 2)

  const columns = [faqs.articles.slice(0, middle), faqs.articles.slice(middle)]

  const handleTopicClick = (topic: FaqResult) => {
    const slug = convertToKebabCase(topic.name).slice(0, 50)
    const sectionName = convertToKebabCase(
      (sectionNames && sectionNames[topic.section_id]) || ""
    )
    segment.track(
      segmentEvents.FAQ_CLICKED,
      {
        question: `${sectionName} - ${slug}`,
      },
      {
        integrations: {
          All: false,
          Iterable: true,
          Heap: true,
        },
      }
    )

    navigate(`/faqs/${sectionName}#${slug}`)
  }

  let header = title
  if (titleType === "number") {
    header = `${faqs.count} topics`
  }

  return (
    <Container modifiedHeader={modifiedHeader}>
      {modifiedHeader ? <H3>{header}</H3> : <H2>{header}</H2>}
      <TopicsContainer>
        {columns?.map((column, index) => (
          <Column key={`column${index}`}>
            {column.map(topic => {
              return (
                <TopicLink
                  key={topic.id}
                  onClick={() => handleTopicClick(topic)}
                >
                  {topic.name}
                </TopicLink>
              )
            })}
          </Column>
        ))}
      </TopicsContainer>
    </Container>
  )
}
