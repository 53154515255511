import { useState } from "react"
import { Plan, H2, PlanType, Slider } from "../../ui"
import {
  PlansWrapper,
  PlansDesktopWrapper,
  Description,
  LearnMore,
  MoreLikelyText,
  StrongStartText,
  AsteriskText,
  PlansMobileWrapper,
} from "./styled"

interface PlansProps {
  plans: PlanType[]
  mobilePlans: PlanType[]
  hideLearnMoreLink?: boolean
  padding?: string
  background?: string
  signupTitle?: string
}

export const Plans = ({
  plans,
  mobilePlans,
  hideLearnMoreLink,
  padding,
  background,
  signupTitle,
}: PlansProps): JSX.Element => {
  const [hover, setHover] = useState<number | null>(null)
  return (
    <PlansWrapper padding={padding} background={background}>
      <H2>Feel better. Save money.</H2>
      <Description>
        You are <MoreLikelyText> 3x more likely*</MoreLikelyText> to feel better
        if you stick with a Cerebral plan for 2+ months. <br />
        Commit to yourself with a{" "}
        <StrongStartText>Strong Start</StrongStartText> package to see the best
        results, and reward yourself by saving money as you get started.
      </Description>
      <PlansDesktopWrapper>
        {plans.map(plan => (
          <Plan
            plan={plan}
            key={plan.id}
            hover={plan.id === hover}
            setHover={setHover}
            signupTitle={signupTitle}
          />
        ))}
      </PlansDesktopWrapper>

      <PlansMobileWrapper>
        <Slider>
          {mobilePlans.map(plan => (
            <Plan
              plan={plan}
              hover={plan.id === hover}
              setHover={setHover}
              key={plan.id}
              signupTitle={signupTitle}
            />
          ))}
        </Slider>
      </PlansMobileWrapper>
      <AsteriskText>
        *based on Cerebral clients with moderate to severe anxiety and/or
        depression from 2020-2023.
      </AsteriskText>

      {!hideLearnMoreLink && (
        <LearnMore href="/plans">Learn more about our plans</LearnMore>
      )}
    </PlansWrapper>
  )
}
