import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin: 64px;
  justify-content: space-between;
  align-items: left;
  text-align: left;
  margin-left: 20%;
  & h3 {
    justify-content: left;
    margin: 24px 0px;
  }
  @media (max-width: 1000px) {
    margin-left: 24px;
    & h3 {
      justify-content: left;
      margin: 0px;
    }
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 23px;
  flex-wrap: wrap;
  width: 900px;
  height: 600px;
  flex: 2;
  column-gap: 150px;
  & a {
    margin: 12px 8px 12px 0px;
    color: ${colors.c_secondary[600]};
  }
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: none;
    width: fit-content;
    height: fit-content;
    & a {
      color: ${colors.c_secondary[600]};
      margin: 8px 0px;
      font-size: 14px;
    }
  }
`

export const AssociatedLink = styled.a`
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: ${colors.c_secondary[600]};
  margin: 8px auto 0;
  @media (max-width: 1000px) {
    font-size: 14px;
  }
`
